.home-page {

    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 50%;
        max-height: 90%;
    }

    h1 {
        color: #f3ffcc;
        font-size: 65px;
        margin: 0;
        font-family: "Coolvetica";
        font-weight: 400;
        
        &::before {
            content: "<h1>";
            font-family: "La Belle Aurore";
            color: #f3ffcc;
            font-size: 18px;
            position: absolute;
            margin-top: -20px;
            left: 6px;
            // opacity: 0.6;
        }

        &::after {
            content: "</h1>";
            font-family: "La Belle Aurore";
            color: #f3ffcc;
            font-size: 18px;
            position: absolute;
            margin-top: 40px; //TODO: make <h1/> on home page lower
            margin-left: 20px;
            animation: fadeIn 1s 1s backwards;
            // opacity: 0.6;
        }

        img {
            width: 32px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }

    h2 {
        color: #8d8d8d;
        margin-top: 10px;
        font-weight: 400;
        font-size: 15px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 3s 3.5s backwards;
    }

    .flat-button {
        color:#f3ffcc;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin-top: 15px;
        float: left;
        animation: fadeIn 3s 4s backwards;
        white-space: nowrap;

        &:hover { //TODO: change contact me button hover effect
            background: #aa9209;
            color: #333;
        }
    }

    
}